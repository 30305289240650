import { AlertTriangleIcon, InfoIcon } from '@assets/icons';
import { Tooltip } from '@components/tooltip';
import { Typography } from '@components/typography';
import { Box, Divider, Stack, useTheme } from '@mui/material';
import { SelectionContext, ZoomContext } from '@subsets/workspaces';
import { Node as NodeType } from '@subsets/workspaces/graphs/graph/types';
import { useObservableState } from 'observable-hooks';
import { MutableRefObject, forwardRef, useContext, useMemo } from 'react';
import { InputPort } from './InputPort';
import { NodeActionController } from './NodeActionController';
import { OutputPort } from './OutputPort';
import { NodeState } from './types';

const NodeStateIcon = ({ state }: { state: NodeState }) => {
  const { palette } = useTheme();
  if (state !== 'outdated' && state !== 'error') return null;
  return (
    <Stack
      sx={{
        position: 'absolute',
        top: -14,
        right: -14,
        borderRadius: '50%',
        backgroundColor: palette.background.paper,
        height: '28px',
        width: '28px',
        alignItems: 'center',
        paddingTop: '2px',
      }}
    >
      {
        <AlertTriangleIcon
          color={state === 'error' ? palette.error.main : palette.warning.main}
          size={20}
        />
      }
    </Stack>
  );
};

const nodeBorderColorMap = {
  active: 'graph.node.border.active',
  focus: 'graph.node.border.active',
  error: 'graph.node.border.error',
  default: 'graph.node.border.default',
  outdated: 'warning.main',
};

type NodeProps = NodeType & {
  index?: number;
  disabled?: boolean;
  container?: MutableRefObject<Element>;
  state?: NodeState;
  onOutputPortSelect?: (portId?: string) => void;
  onInputPortSelect?: (portId?: string) => void;
  gTestId?: string;
  nodeTestId?: string;
};

export const Node = forwardRef<SVGGElement, NodeProps>(
  (
    {
      name,
      ports,
      nodeClass,
      state,
      tooltip,
      values,
      onOutputPortSelect,
      onInputPortSelect,
      container,
      color,
      index,
      disabled,
      gTestId = 'gTestId',
      nodeTestId = 'nodeTestId',
    },
    ref,
  ) => {
    const { palette } = useTheme();

    // This observable is required to trigger a refresh of the element ref positions so that the
    // NodeActionController can be positioned correctly.
    const { percent: percentObservable } = useContext(ZoomContext);
    const { activeNodeInformationNode, informationDrawerOpen, toggleInformationDrawer } =
      useContext(SelectionContext);
    const percent = useObservableState(percentObservable);

    const displayName = useMemo(() => {
      if (nodeClass === 'VolumeDirectory') {
        const nameParts = (values?.Directory as string)?.match(/(.+):(.+)/);
        return nameParts?.[2] || nameParts?.[1] || values?.Directory || '-';
      }
      if (nodeClass === 'VolumeFile') {
        const nameParts = (values?.File as string)?.match(/(.+):(.+)/);
        return (
          nameParts?.[2].split('/')?.at(-1)?.split('.')?.[0] ||
          nameParts?.[2] ||
          values?.File ||
          '-'
        );
      }
      return nodeClass;
    }, [name]);
    return (
      <g
        ref={ref}
        data-testid={gTestId}
        style={{
          overflow: 'visible',
          position: 'relative',
          cursor: 'pointer',
        }}
        id={name}
      >
        <foreignObject width={1} height={1} style={{ overflow: 'visible' }}>
          <NodeActionController
            nodeId={name}
            visible={state === 'focus'}
            disabled={disabled}
            anchorEl={container.current as HTMLElement}
          />
          <Box
            ref={container}
            data-testid={nodeTestId}
            width="fit-content"
            position="fixed"
            sx={{
              zIndex: index + 10000,
              borderRadius: '3px',
              transition: 'all 0.1s ease',
              borderColor: nodeBorderColorMap[state],
              borderWidth: '2px',
              backgroundColor: palette.graph.node.background,
              '&:hover': {
                borderColor: palette.graph.node.border.hover,
                '& .port-icon-bar': {
                  stroke: palette.graph.node.border.hover,
                },
              },
              '&:hover .port, &:hover .icon': {
                transition: 'all 0.1s ease',
                borderColor: palette.grey[700],
                stroke: palette.grey[700],
              },
            }}
            border={2}
            pb={6}
          >
            <Box
              sx={{
                backgroundColor: color,
                height: '8px',
                width: '100%',
              }}
            />
            <Stack gap={1.5} width={375}>
              <Stack
                direction="row"
                px={4}
                alignItems="center"
                justifyContent="space-between"
                gap={2}
                sx={{ height: '50px', mb: -2 }}
              >
                <Typography
                  handleOverFlow
                  variant="subtitle1"
                  sx={{
                    fontSize: '1.5rem', // (16 / 200) * (200 - percent) + 16,
                    lineHeight: '50px',
                  }}
                >
                  {displayName}
                </Typography>
                {Boolean(tooltip) && (
                  <Tooltip
                    variant="secondary"
                    title={
                      <Stack>
                        <Typography variant="caption2" sx={{ whiteSpace: 'pre-line' }}>
                          {tooltip.replaceAll('\\n', '\n')}
                        </Typography>
                        <Divider sx={{ my: 1 }} />
                        <Typography variant="caption2" mx="auto">
                          Click for more information
                        </Typography>
                      </Stack>
                    }
                  >
                    <InfoIcon
                      size={28}
                      onClick={() => {
                        activeNodeInformationNode.next(
                          nodeClass === 'VolumeFile'
                            ? (values?.File as string).split('/').at(-1).split('.')[0]
                            : nodeClass,
                        );
                        if (!informationDrawerOpen) {
                          toggleInformationDrawer();
                        }
                      }}
                    />
                  </Tooltip>
                )}
              </Stack>
              {ports.inputs.map((input) => (
                <InputPort
                  key={`input-${input.name}-${input.name}`}
                  {...input}
                  index={index}
                  nodeId={name}
                  portId={`input-${input.name}`}
                  state={state}
                  value={values[input.name]}
                  onSelect={onInputPortSelect}
                  disabled={disabled}
                />
              ))}
              {ports.outputs.map((output) => (
                <OutputPort
                  key={`output-${output.name}-${output.name}`}
                  {...output}
                  nodeId={name}
                  portId={`output-${output.name}`}
                  state={state}
                  onSelect={onOutputPortSelect}
                />
              ))}
            </Stack>
            <NodeStateIcon state={state} />
          </Box>
        </foreignObject>
      </g>
    );
  },
);

export default Node;
