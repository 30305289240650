import dagre from 'dagre';
import { find, map as mapLodash } from 'lodash';
import {
  MutableRefObject,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { distinct, filter, map } from 'rxjs/operators';
import { GraphDataContext } from './GraphDataProvider';
import { ZoomContext } from './ZoomProvider';

interface PositionContextType {
  getNodePosition: (nodeId: string) => BehaviorSubject<[number, number]>;
  getNodeListInBound: (from: [number, number], to: [number, number]) => string[];
  registerNodeReference: (nodeId: string, ref: MutableRefObject<HTMLElement>) => void;
  registerPortReference: (
    nodeId: string,
    portId: string,
    ref: MutableRefObject<HTMLElement>,
  ) => void;
  getPortPosition: (
    nodeId: string,
    portId: string,
    inputPort?: boolean,
  ) => Observable<[number, number]>;
  autoLayout: () => void;
  focusNode: (nodeId: string) => void;
}

type PositionType = { nodeId: string };
export const PositionContext = createContext({} as PositionContextType);

const isIntersect = (rectangleA: Rectangle, rectangleB: Rectangle) => {
  const [[x1, y1], [x2, y2]] = rectangleA;
  const [[x3, y3], [x4, y4]] = rectangleB;
  if (x1 >= x4 || x3 >= x2) {
    return false;
  }
  if (y2 >= y3 || y4 >= y1) {
    return false;
  }

  return true;
};
type Rectangle = [[number, number], [number, number]];
export const PositionProvider = ({ children }: PropsWithChildren) => {
  const [nodePosition] = useState<
    BehaviorSubject<Record<string, BehaviorSubject<[number, number]>>>
  >(new BehaviorSubject({}));
  const [portPosition] = useState<
    BehaviorSubject<Record<string, BehaviorSubject<[number, number]>>>
  >(new BehaviorSubject({}));
  const [nodeRef] = useState<BehaviorSubject<Record<string, Element>>>(new BehaviorSubject({}));
  const [portRef] = useState<BehaviorSubject<Record<string, Element>>>(new BehaviorSubject({}));
  const { nodes, links, ready$: ready, updateNodeLocation } = useContext(GraphDataContext);
  const { centerGraphElements, zoomTo, scale } = useContext(ZoomContext);

  useEffect(() => {
    const shouldAutoLayout = nodes.every(
      (node) => !node.location || (node.location.x === 0 && node.location.y === 0),
    );
    if (!shouldAutoLayout) {
      return;
    }
    if (process.env.JEST_WORKER_ID !== undefined) {
      return;
    }
    const g = new dagre.graphlib.Graph({});

    g.setGraph({
      nodesep: 3,
      ranksep: 10,
      rankdir: 'LR',
    });

    g.setDefaultEdgeLabel(() => ({}));

    nodes.forEach(({ name }) => {
      const element = nodeRef.getValue()[name];
      const { width, height } = element.getBoundingClientRect();

      const scaled = 2 / scale.getValue();
      g.setNode(name, { width: width * scaled, height: height * scaled });
      return null;
    });

    mapLodash(
      links as { id: string; from: PositionType; to: PositionType }[],
      (link: { id: string; from: PositionType; to: PositionType }) => {
        const { id, from, to } = link;
        const source = from?.nodeId;
        const target = to?.nodeId;
        g.setEdge(source, target, { id });
        return null;
      },
    );

    dagre.layout(g);

    const updateNodeLocations = async () => {
      await Promise.all(
        g.nodes().map((id, index) => {
          const { x, y } = g.node(id) || { x: 0, y: 0 };
          getNodePosition(id).next([x, y]);
          updateNodeLocation(id, { x, y }, index === g.nodes().length - 1);
          return null;
        }),
      );
      centerGraphElements();
    };

    updateNodeLocations();
  }, [nodes]);

  const focusNode = () => {
    zoomTo(100);
  };

  const getNodePosition = (nodeId: string): BehaviorSubject<[number, number]> => {
    let position = nodePosition.getValue()[nodeId];
    if (position) {
      return position;
    }
    const storedPosition = find(nodes, { name: nodeId }).location as { x: number; y: number };
    const { x = 0, y = 0 } = storedPosition || { x: Math.random() * 1000, y: Math.random() * 1000 };

    position = new BehaviorSubject<[number, number]>([x, y]) as never;

    const update = { ...nodePosition.getValue() };
    update[nodeId] = position;
    nodePosition.next(update);
    return position;
  };

  const autoLayout = async () => {
    if (process.env.JEST_WORKER_ID !== undefined) {
      return;
    }
    const g = new dagre.graphlib.Graph({});

    g.setGraph({
      nodesep: 3,
      ranksep: 10,
      rankdir: 'LR',
    });

    g.setDefaultEdgeLabel(() => ({}));

    nodes.forEach(({ name }) => {
      const element = nodeRef.getValue()[name];
      const { width, height } = element.getBoundingClientRect();

      const scaled = 2 / scale.getValue();
      g.setNode(name, { width: width * scaled, height: height * scaled });
      return null;
    });

    mapLodash(
      links as { id: string; from: PositionType; to: PositionType }[],
      (link: { id: string; from: PositionType; to: PositionType }) => {
        const { id, from, to } = link;
        const source = from?.nodeId;
        const target = to?.nodeId;
        g.setEdge(source, target, { id });
        return null;
      },
    );

    dagre.layout(g);

    await Promise.all(
      g.nodes().map((id, index) => {
        const { x, y } = g.node(id) || { x: 0, y: 0 };
        getNodePosition(id).next([x, y]);
        updateNodeLocation(id, { x, y }, index === g.nodes().length - 1);
        return null;
      }),
    );
    centerGraphElements();
  };

  const getNodeListInBound = (from: [number, number], to: [number, number]): string[] => {
    const result = [];
    const nodeIds = Object.keys(nodePosition.getValue());
    nodeIds.map((nodeId: string) => {
      const position = getNodePosition(nodeId);
      const element = nodeRef.getValue()[nodeId];
      if (!!position || !!element) {
        const k = scale.getValue();

        const { height, width } = element.getBoundingClientRect();
        const [x, y] = position.getValue();
        const [x1, y1] = from;
        const [x2, y2] = to;

        const nodeRect: Rectangle = [
          [x, y + height / k],
          [x + width / k, y],
        ];
        const selectionRect: Rectangle = [
          [Math.min(x1, x2), Math.max(y1, y2)],
          [Math.max(x1, x2), Math.min(y1, y2)],
        ];

        if (isIntersect(selectionRect, nodeRect)) {
          result.push(nodeId);
        }
      }
      return nodeId;
    });
    return result as string[];
  };

  const registerNodeReference = (nodeId: string, ref: MutableRefObject<HTMLElement>) => {
    const copy = { ...nodeRef.getValue() };
    copy[`${nodeId}`] = ref.current;
    nodeRef.next(copy);
  };

  const registerPortReference = (
    nodeId: string,
    portId: string,
    ref: MutableRefObject<HTMLElement>,
  ) => {
    const copy = { ...portRef.getValue() };
    copy[`${nodeId}.${portId}`] = ref.current;
    portRef.next(copy);
  };

  const getPortPosition = (nodeId: string, portId: string) => {
    const position = portPosition?.getValue()[`${nodeId}.${portId}`];
    if (position) {
      return position;
    }
    const findNodeRef = nodeRef.pipe(
      map((nodeMap) => nodeMap[nodeId]),
      filter((d) => !!d),
      distinct(),
    );

    const findPortRef = portRef.pipe(
      map((portMap) => portMap[`${nodeId}.${portId}`]),
      filter((d) => !!d),
      distinct(),
    );

    const position$ = combineLatest([
      findNodeRef,
      findPortRef,
      getNodePosition(nodeId),
      ready,
    ]).pipe(
      map(([latestNodeRef, latestPortRef, [x, y]]) => {
        const scaleValue = scale.getValue();
        const { top: y1, height } = latestPortRef?.getBoundingClientRect() as {
          height: number;
          top: number;
        };
        const { width, top: y2 } = latestNodeRef?.getBoundingClientRect() as {
          width: number;
          top: number;
        };
        const top = (y1 - y2 + height / 2) / scaleValue;
        if (portId.startsWith('input')) {
          return [x - 5, y + top] as [number, number];
        }
        return [x + (width + 2.5) / scaleValue, y + top] as [number, number];
      }),
    ) as BehaviorSubject<[number, number]>;
    const update = { ...portPosition.getValue() };
    update[`${nodeId}.${portId}`] = position$;
    portPosition.next(update);
    return position$;
  };

  const providerValue = useMemo(
    () => ({
      getNodeListInBound,
      getNodePosition,
      registerNodeReference,
      registerPortReference,
      getPortPosition,
      autoLayout,
      focusNode,
    }),
    [
      getNodeListInBound,
      getNodePosition,
      registerNodeReference,
      registerPortReference,
      getPortPosition,
      autoLayout,
      focusNode,
    ],
  );

  return <PositionContext.Provider value={providerValue}>{children}</PositionContext.Provider>;
};
