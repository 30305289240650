import {
  ArrowLeftIcon,
  ArrowRightIcon,
  FileTextIcon,
  LoadingIcon,
  SearchIcon,
} from '@assets/icons';
import { IconButton } from '@components/button';
import { Image } from '@components/image';
import { Group } from '@components/layout';
import { ExternalLink } from '@components/link';
import { useModal } from '@components/modal';
import { Typography } from '@components/typography';
import {
  useGetChannelNodeDocumentationQuery,
  useGetVolumeDataQuery,
} from '@generated/UseGraphqlHooks';
import { Box, Divider, MenuItem, Stack, useTheme } from '@mui/material';
import { ChannelDetailContext, GraphDataContext, SelectionContext } from '@subsets/workspaces';
import { Volume3DPreviewModal } from '@subsets/workspaces/assets/volumeDetails/Volume3DPreviewModal';
import { ChannelNode } from '@subsets/workspaces/graphs/graph/types';
import MDEditor from '@uiw/react-md-editor';
import { useObservableState } from 'observable-hooks';
import { useContext, useEffect } from 'react';
import { useLocation } from 'react-use';
import rehypeSanitize from 'rehype-sanitize';

const VolumeFileInformation = ({ volumeFile, name, onBack }) => {
  const { data } = useGetVolumeDataQuery({
    variables: {
      volumeId: volumeFile.split(':')[0],
      dir: `${volumeFile.split(':')[1].split('/').slice(0, -1).join('/')}/`,
      keys: [volumeFile.split(':')[1].split('/').slice(-1)[0]],
    },
  });

  const { showModal: showVolume3DPreviewModal } = useModal({
    component: Volume3DPreviewModal,
    componentProps: {
      name: data?.getVolumeData?.keys?.[0]?.key,
      previewUrl: data?.getVolumeData?.keys?.[0]?.previewUrl,
      imageUrl: data?.getVolumeData?.keys?.[0]?.url,
    },
    modalProps: { disableAutoFocus: true, disablePortal: true },
  });

  return (
    <Stack gap={1}>
      <Group alignItems="center" justifyContent="space-between" mb={6}>
        <Group alignItems={'center'} gap={2}>
          <IconButton Icon={ArrowLeftIcon} onClick={onBack} />
          <Typography variant="body1">{name}</Typography>
        </Group>
      </Group>
      <Group alignItems={'center'} justifyContent={'space-between'}>
        <Box>
          {volumeFile
            .split(':')[1]
            .split('/')
            .slice(1)
            .map((pathPart) => (
              <>
                /<wbr />
                <Typography variant="body2" sx={{ display: 'inline-block' }}>
                  {pathPart}
                </Typography>
              </>
            ))}
        </Box>
        {data?.getVolumeData?.keys?.[0]?.previewUrl && (
          <IconButton
            onClick={showVolume3DPreviewModal}
            size={18}
            Icon={SearchIcon}
            aria-label="View 3d Preview"
          />
        )}
      </Group>
      <Image
        url={data?.getVolumeData?.keys?.[0]?.thumbnailUrl}
        alt={volumeFile}
        sx={{ height: 'auto', aspectRatio: '1/1' }}
      />
    </Stack>
  );
};

const NodeDocumentation = ({ channelId, nodeClass, onBack }) => {
  const { palette, typography } = useTheme();
  const location = useLocation();
  useEffect(() => {
    if (document) {
      const anchor = document.querySelector(`[href="${location?.hash}"][tabindex="-1"]`);
      if (anchor) {
        anchor.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location?.hash]);
  const { data, loading } = useGetChannelNodeDocumentationQuery({
    variables: { channelId, nodeClass },
  });

  const markdown = data?.getNodeDocumentation?.documentation || '';
  const { showModal: showVolume3DPreviewModal } = useModal({
    component: Volume3DPreviewModal,
    componentProps: {
      name: nodeClass,
      previewUrl: data?.getNodeDocumentation?.preview,
      imageUrl: data?.getNodeDocumentation?.thumbnail,
    },
    modalProps: { disableAutoFocus: true, disablePortal: true },
  });

  if (loading) {
    return (
      <Stack flex={1} alignItems="center" justifyContent="center">
        <LoadingIcon size={12} />
      </Stack>
    );
  }
  if (!markdown) {
    return <Typography variant="body2">No documentation found for this node.</Typography>;
  }
  return (
    <Stack
      flex={1}
      sx={{
        overflowY: 'auto',
        pr: 1,
        '& .wmde-markdown': {
          color: palette.text.paper,
          backgroundColor: palette.background.paper,
          '& p,td,li': {
            ...typography.caption2,
          },
          '& table': {
            overflowX: 'auto',
            border: `1px solid ${palette.table.cell.border}`,
          },
        },
      }}
    >
      <Group alignItems="center" justifyContent="space-between" mb={6}>
        <Group alignItems={'center'} gap={2}>
          <IconButton Icon={ArrowLeftIcon} onClick={onBack} />
          <Typography variant="body1">{nodeClass}</Typography>
        </Group>
        {(data?.getNodeDocumentation?.preview || data?.getNodeDocumentation?.thumbnail) && (
          <IconButton
            onClick={showVolume3DPreviewModal}
            size={18}
            Icon={SearchIcon}
            aria-label="View 3d Preview"
          />
        )}
      </Group>
      <MDEditor.Markdown source={markdown} rehypePlugins={[[rehypeSanitize]]} />
    </Stack>
  );
};

export const NodeInformation = () => {
  const { graphMetadata, nodes } = useContext(GraphDataContext);
  const { data$ } = useContext(ChannelDetailContext);
  const data = useObservableState<ChannelNode[]>(data$);
  const nodeClasses: Set<[string, boolean]> = new Set();
  nodes.forEach((node) => {
    nodeClasses.add([
      node.nodeClass === 'VolumeFile'
        ? (node?.values?.File as string).split('/').at(-1).split('.')[0]
        : node.nodeClass,
      Boolean(
        node.nodeClass === 'VolumeFile' ||
          data?.find((nodeClass) => nodeClass.name === node.nodeClass)?.help,
      ),
    ]);
  });

  const { activeNodeInformationNode } = useContext(SelectionContext);
  const activeNode = useObservableState(activeNodeInformationNode);
  if (activeNode) {
    const volumeFile = nodes.find(
      (n) => n.nodeClass === 'VolumeFile' && n.tooltip?.includes(activeNode),
    )?.values.File;

    return (
      <Stack gap={6} height="100%">
        {volumeFile ? (
          <VolumeFileInformation
            volumeFile={volumeFile}
            name={activeNode}
            onBack={() => {
              activeNodeInformationNode.next('');
              if (window?.location.hash) {
                window.history.replaceState('', document?.title, window.location.pathname);
              }
            }}
          />
        ) : (
          <NodeDocumentation
            channelId={graphMetadata?.channelId}
            nodeClass={activeNode}
            onBack={() => {
              activeNodeInformationNode.next('');
              if (window?.location.hash) {
                window.history.replaceState('', document?.title, window.location.pathname);
              }
            }}
          />
        )}
      </Stack>
    );
  }
  return (
    <Stack>
      <Group alignItems="center" justifyContent="space-between">
        <Typography variant="subtitle2">{graphMetadata?.channel || ''}</Typography>
        <ExternalLink unstyled href={`/documentation?channelId=${graphMetadata?.channelId}`}>
          <Group>
            <FileTextIcon size={16} />
            <ArrowRightIcon size={16} />
          </Group>
        </ExternalLink>
      </Group>
      <Divider sx={{ my: 4 }} />
      <Typography variant="caption2" sx={{ mb: 2 }}>
        Graph nodes
      </Typography>
      <Stack gap={1}>
        {Array.from(nodeClasses)
          .sort((a, b) => a[0].localeCompare(b[0]))
          .map(([nodeClass, hasHelp]) => (
            <MenuItem
              key={nodeClass}
              disabled={!hasHelp}
              onClick={hasHelp ? () => activeNodeInformationNode.next(nodeClass) : () => {}}
            >
              <Typography variant="body2">{nodeClass}</Typography>
            </MenuItem>
          ))}
      </Stack>
    </Stack>
  );
};
