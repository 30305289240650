import { ArrowRightIcon, FileTextIcon, SearchIcon } from '@assets/icons';
import { TextInput } from '@components/form';
import { Group } from '@components/layout';
import { ExternalLink } from '@components/link';
import { TreeItem, TreeView } from '@components/tree';
import { Typography } from '@components/typography';
import { useSearch } from '@hooks/UseSearch';
import { Box, Popover, Stack, useTheme } from '@mui/material';
import { ChannelDetailContext, GraphDataContext, ZoomContext } from '@subsets/workspaces';
import { ChannelNode } from '@subsets/workspaces/graphs/graph/types';
import { filter, isNil, map, matches, property, size, uniq } from 'lodash';
import { useObservableState } from 'observable-hooks';
import { useContext, useState } from 'react';

export const ChannelNodeTypeViewLabel = ({
  nodeType,
  categoryColor,
  arrow,
}: {
  nodeType: ChannelNode;
  categoryColor: string;
  arrow: boolean;
}) => {
  const { palette } = useTheme();
  const { name, alias } = nodeType;
  const { createNode } = useContext(GraphDataContext);
  const { getCenterPosition } = useContext(ZoomContext);
  const onCreateNode = () => {
    void createNode(nodeType, getCenterPosition());
  };
  return (
    <Box
      pb={2}
      pt={2}
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      gap={2}
      data-testid="createNodeButton"
      onClick={onCreateNode}
    >
      <Box height="16px" width="16px" borderRadius="2px" bgcolor={categoryColor} />
      <Typography data-cy="Channel-Node-Tree-View-Channel-Node-Type-View" variant="body2">
        {alias || name}
      </Typography>
      {arrow && (
        <Box
          sx={{
            width: 0,
            height: 0,
            borderTop: '12px solid transparent',
            borderBottom: '12px solid transparent',
            borderRight: `12px solid ${palette.grey[900]}`,
            position: 'absolute',
            right: '-8px',
          }}
        />
      )}
    </Box>
  );
};

const ChannelNodeTooltip = ({ node }: { node: ChannelNode }) => {
  const { palette } = useTheme();
  const tooltip = node?.tooltip.replaceAll('\\n', '\n');
  return (
    <Stack
      gap={1}
      width="256px"
      sx={{
        border: `8px solid ${palette.grey[900]}`,
        backgroundColor: palette.grey[900],
      }}
    >
      <Typography variant="caption2" color={palette.grey[100]} sx={{ whiteSpace: 'pre-line' }}>
        {tooltip}
      </Typography>
      {node?.thumbnail && <img src={node?.thumbnail} alt={node?.name} />}
    </Stack>
  );
};

export const ChannelNodeTypeView = ({
  nodeType,
  categoryColor,
}: {
  nodeType: ChannelNode;
  categoryColor: string;
}) => {
  const { category, subcategory, name } = nodeType;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  return (
    <>
      <TreeItem
        sx={{
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
        nodeId={
          `${category || 'category'}.${subcategory || 'subcategory'}.${name}` ||
          Math.random().toString()
        }
        label={ChannelNodeTypeViewLabel({ nodeType, categoryColor, arrow: Boolean(anchorEl) })}
        onMouseEnter={(event) => setAnchorEl(event.currentTarget)}
        onMouseLeave={() => setAnchorEl(null)}
      />
      <Popover
        open={Boolean(anchorEl) && (Boolean(nodeType?.tooltip) || Boolean(nodeType?.thumbnail))}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        disablePortal
        style={{ pointerEvents: 'none' }}
        hideBackdrop
        sx={{
          '& .MuiPopover-paper': {
            backgroundColor: 'transparent',
            backgroundImage: 'none',
            boxShadow: 'none',
          },
        }}
      >
        <ChannelNodeTooltip node={nodeType} />
      </Popover>
    </>
  );
};

export const ChannelSubCatalogView = ({
  data,
  category,
  subcategory,
}: {
  data: ChannelNode[];
  category: string;
  subcategory: string;
}) => {
  const { palette } = useTheme();
  const nodeList = isNil(category)
    ? data.filter(({ category: nodeCategory }) => isNil(nodeCategory))
    : data.filter(
        ({ category: nodeCategory, subcategory: nodeSubcategory }) =>
          category === nodeCategory && subcategory === nodeSubcategory,
      );

  nodeList.sort((a, b) => a.name.localeCompare(b.name));
  const [categoryColor] = map(filter(data, matches({ category })), property('color'));

  return (
    <TreeItem
      sx={{
        '&:hover': {
          backgroundColor: 'transparent',
        },
        marginLeft: 8,
        '& .MuiTreeItem-group': {
          marginLeft: 0,
        },
      }}
      nodeId={
        !isNil(category) && !isNil(subcategory)
          ? `${category}.${subcategory}`
          : Math.random().toString()
      }
      label={
        <Box pt={2} pb={2} color="grey.700">
          <Typography variant="body2" color={palette.text.paper}>
            {isNil(subcategory) ? 'Undefined' : subcategory}
          </Typography>
        </Box>
      }
    >
      {nodeList.map((node) => {
        return (
          <ChannelNodeTypeView
            key={`${category}|${subcategory}|${node.name}`}
            nodeType={node}
            categoryColor={(categoryColor as string) || 'secondary.main'}
          />
        );
      })}
    </TreeItem>
  );
};

export const ChannelCatalogView = ({
  category,
  data,
}: {
  category: string;
  data: ChannelNode[];
}) => {
  const { palette } = useTheme();
  const subCategoryList = uniq(map(filter(data, matches({ category })), property('subcategory')));
  subCategoryList.sort();
  const [categoryColor] = map(filter(data, matches({ category })), property('color'));

  return (
    <TreeItem
      nodeId={isNil(category) ? 'Undefined' : category || Math.random().toString()}
      label={
        <Box py={2} display="flex" justifyContent="flex-start" alignItems="center" gap={2}>
          <Box
            height="16px"
            width="16px"
            borderRadius="2px"
            bgcolor={(categoryColor as string) || 'secondary.main'}
          />
          <Typography data-cy="Channel-Node-Tree-View-Channel-Catalog-View" variant="body1">
            {isNil(category) ? 'Undefined' : category}
          </Typography>
        </Box>
      }
      sx={{ py: 2 }}
    >
      {map(
        size(subCategoryList) > 0 ? subCategoryList : ['Undefined'],
        (subCategory: string, index) => {
          return (
            <ChannelSubCatalogView
              data={data}
              key={`${subCategory} + ${index}`}
              category={category}
              subcategory={subCategory}
            />
          );
        },
      )}
    </TreeItem>
  );
};

export const ChannelNodeTreeView = () => {
  const { data$ } = useContext(ChannelDetailContext);
  const { graphMetadata } = useContext(GraphDataContext);
  const data = useObservableState<ChannelNode[]>(data$);
  const { data: categoryData, onSearch } = useSearch(data, ['category', 'name', 'subcategory']);
  const categoryList = uniq(map(categoryData, property('category')));
  categoryList.sort();
  const handleSearchChange = ({ target: { value } }: { target: { value: string } }) => {
    onSearch(value);
  };
  return (
    <Stack gap={4} pt={4}>
      <Group alignItems="center" justifyContent="space-between">
        <Typography variant="subtitle2">{graphMetadata?.channel || ''}</Typography>
        <ExternalLink unstyled href={`/documentation?channelId=${graphMetadata?.channelId}`}>
          <Group>
            <FileTextIcon size={16} />
            <ArrowRightIcon size={16} />
          </Group>
        </ExternalLink>
      </Group>
      {size(data) > 0 && (
        <TextInput
          data-cy="Channel-Node-Tree-View-Search"
          data-testid="search"
          name="search"
          startIcon={SearchIcon}
          onChange={handleSearchChange}
        />
      )}
      <TreeView>
        {size(categoryList) === 0 && (
          <Box display="flex" justifyContent="center">
            <Typography variant="body1">There is no node to choose from.</Typography>
          </Box>
        )}
        {size(categoryList) > 0 &&
          map(categoryList, (category: string, index) => {
            return (
              <ChannelCatalogView
                category={category}
                data={categoryData as ChannelNode[]}
                key={`${category} + ${index}`}
              />
            );
          })}
      </TreeView>
    </Stack>
  );
};
